@font-face {
  font-family: "Avenir_300";
  src: url("/assets/fonts/Avenir-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_300Oblique";
  src: url("/assets/fonts/Avenir-LightOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_900";
  src: url("/assets/fonts/Avenir-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_BlackOblique";
  src: url("/assets/fonts/Avenir-BlackOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_400";
  src: url("/assets/fonts/Avenir-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_BookOblique";
  src: url("/assets/fonts/Avenir-BookOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_400_Roman";
  src: url("/assets/fonts/Avenir-Roman.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_400Oblique_Roman";
  src: url("/assets/fonts/Avenir-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_500";
  src: url("/assets/fonts/Avenir-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_500Oblique";
  src: url("/assets/fonts/Avenir-MediumOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_800";
  src: url("/assets/fonts/Avenir-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_800BlackOblique";
  src: url("/assets/fonts/Avenir-BlackOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_900";
  src: url("/assets/fonts/Avenir-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir_900HeavyOblique";
  src: url("/assets/fonts/Avenir-HeavyOblique.ttf") format("truetype");
}
