/* stylelint-disable no-invalid-position-at-import-rule */
@use "sass:map";
@import "colors";

// Media step breakpoint mixin based on Angular Material lib
$breakpoints: (
  xs: "screen and (max-width: 599px)",
  sm: "screen and (min-width: 600px) and (max-width: 959px)",
  md: "screen and (min-width: 960px) and (max-width: 1279px)",
  lg: "screen and (min-width: 1280px) and (max-width: 1919px)",
  xl: "screen and (min-width: 1920px) and (max-width: 5000px)",
  lt-sm: "screen and (max-width: 599px)",
  lt-md: "screen and (max-width: 959px)",
  lt-lg: "screen and (max-width: 1279px)",
  lt-xl: "screen and (max-width: 1919px)",
  gt-xs: "screen and (min-width: 600px)",
  gt-sm: "screen and (min-width: 960px)",
  gt-md: "screen and (min-width: 1280px)",
  gt-lg: "screen and (min-width: 1920px)"
) !default;

// Re-map the breakpoints for the helper classes
$helper-breakpoints: (
  xs: null,
  sm: "gt-xs",
  md: "gt-sm",
  lg: "gt-md",
  xl: "gt-lg"
);

@mixin media-breakpoint($breakpointName) {
  $media-query: map.get($breakpoints, $breakpointName);

  @if not $media-query {
    @content;
  } @else {
    @media #{$media-query} {
      @content;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Position helpers
// -----------------------------------------------------------------------------------------------------
@each $breakpoint, $materialBreakpoint in $helper-breakpoints {
  @include media-breakpoint($materialBreakpoint) {
    $infix: if($materialBreakpoint == null, "", "-#{$breakpoint}");

    .position#{$infix}-relative {
      position: relative;
    }

    .position#{$infix}-absolute {
      position: absolute;
    }

    .position#{$infix}-static {
      position: static;
    }

    .position#{$infix}-fixed {
      position: fixed;
    }

    .position#{$infix}-sticky {
      position: sticky;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Absolute position alignment helpers
// -----------------------------------------------------------------------------------------------------
@each $breakpoint, $materialBreakpoint in $helper-breakpoints {
  @include media-breakpoint($materialBreakpoint) {
    $infix: if($materialBreakpoint == null, "", "-#{$breakpoint}");

    .align#{$infix}-top {
      top: 0;
    }

    .align#{$infix}-right {
      right: 0;
    }

    .align#{$infix}-bottom {
      bottom: 0;
    }

    .align#{$infix}-left {
      left: 0;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Size helpers
// -----------------------------------------------------------------------------------------------------
@each $prop, $abbrev in (height: h, width: w) {
  @for $index from 0 through 180 {
    $size: $index * 4;
    $length: #{$size}px;

    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
      min-#{$prop}: $length !important;
      max-#{$prop}: $length !important;
    }
  }

  // Percentage
  @for $i from 0 through 20 {
    $i-p: 5 * $i;
    $size-p: 5% * $i;

    .#{$abbrev}-#{$i-p}-p {
      #{$prop}: $size-p !important;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Spacing helpers
// -----------------------------------------------------------------------------------------------------
@each $breakpoint, $materialBreakpoint in $helper-breakpoints {
  @include media-breakpoint($materialBreakpoint) {
    $infix: if($materialBreakpoint == null, "", "-#{$breakpoint}");

    @each $prop, $abbrev in (margin: m, padding: p) {
      @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }

      @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
          #{$prop}-left: $length !important;
        }

        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
          #{$prop}-bottom: $length !important;
        }
      }

      @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        .#{$abbrev}t#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }

        .#{$abbrev}r#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }

        .#{$abbrev}b#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}l#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }

      @if $abbrev == m {
        // Some special margin utils for flex alignments
        .m#{$infix}-auto {
          margin: auto !important;
        }

        .mt#{$infix}-auto {
          margin-top: auto !important;
        }

        .mr#{$infix}-auto {
          margin-right: auto !important;
        }

        .mb#{$infix}-auto {
          margin-bottom: auto !important;
        }

        .ml#{$infix}-auto {
          margin-left: auto !important;
        }

        .mx#{$infix}-auto {
          margin-right: auto !important;
          margin-left: auto !important;
        }

        .my#{$infix}-auto {
          margin-top: auto !important;
          margin-bottom: auto !important;
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Cursor helpers
// -----------------------------------------------------------------------------------------------------
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-none {
  pointer-events: none;
}

.ml-0 {
  margin-left: 0 !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Borders helpers
// -----------------------------------------------------------------------------------------------------
.border-bottom-400 {
  border-bottom: 1px solid $primary-400;
}

.border-bottom-white {
  border-bottom: 1px solid $basic-off-white;
}

.border-top-400 {
  border-top: 1px solid $primary-400;
}

.border-left-400 {
  border-left: 1px solid $primary-400;
}

.border-left-primary {
  border-left: 1px solid $primary;
}

.border-right {
  &.primary-400 {
    border-right: 1px solid $primary-400;
  }

  &.white {
    border-right: 1px solid $basic-off-white;
  }
}

.border-primary-400 {
  border: 1px solid $primary-400;
}

.border-400 {
  border: 1px solid $primary-400;
  border-radius: 8px;
}

.circle-border {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 2px solid $basic-off-white;
}

// -----------------------------------------------------------------------------------------------------
// @ Container helpers
// -----------------------------------------------------------------------------------------------------
.container-fill {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}

.flex-fill {
  flex: 1 1 auto;
  min-width: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-auto {
  flex: 0 0 auto;
}

// Background colors
.background-transparent {
  background: transparent;
}

.background-white {
  background: $basic-off-white;
}

.background-primary {
  background: $primary;
}

.background-secondary {
  background: $secondary;
}

.background-electric {
  background: $electric;
}

// Border radius
.br-2 {
  border-radius: 2px;
}

.br-6 {
  border-radius: 6px;
}

.br-8 {
  border-radius: 8px;
}

.br-top-8 {
  border-radius: 8px 8px 0 0;
}

.br-12 {
  border-radius: 12px;
}

.br-16 {
  border-radius: 16px;
}

.br-30 {
  border-radius: 30px;
}

.br-40 {
  border-radius: 40px;
}

.z-index-1 {
  z-index: 1;
}

.list-item {
  display: list-item;
}
