.sleep-health-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.tick {
  white-space: pre;
}

.axis {
  font-family: "Avenir_900";
  font-size: 14px;
  line-height: 18px;
  color: #F2F5F7;
}

span.sh-label {
    font-size: 12px;
    line-height: 16px;
    color: #74767C;
}

span.sh-value {
    font-size: 12px;
    line-height: 16px;
    color: black;
    font-weight: bold;
}
