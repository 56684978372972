// Primary
$primary: #003057;
$primary-500: #122e45;
$primary-400: #335978;
$primary-300: #597287;
$primary-200: #99abbd;
$primary-100: #ccd6de;
$primary-50: #e5ebed;

// Secondary
$secondary: #001d34;
$basic-off-white: #f2f5f7;
$basic-black: #000;

// Electric
$electric: #6bfcac;
$electric-300: #a6fdcd;
$electric-200: #c4fede;

// Rain Blue
$rain-blue: #85f5ff;
$rain-blue-300: #b5faff;
$rain-blue-200: #cffaff;

// Yellow
$yellow: #ffc511;
$yellow-300: #ffdc70;
$yellow-200: #ffe8a0;

// Red
$hot-red: #f84b20;
$hot-red-300: #fb9379;
$hot-red-200: #fcb7a6;

// Shadow
$shadow: rgba(50, 50, 71, 0.08);

