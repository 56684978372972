@use "@angular/material" as mat;
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import "colors";
@include mat.all-component-typographies;
@include mat.core;

.mat-mdc-dialog-container {
  position: relative;
  margin: 0 auto;
  border-radius: 12px !important;
  max-width: 90vw !important;
  color: inherit !important;
  overflow-x: hidden !important;
}

// stylelint-disable-next-line selector-class-pattern
.mdc-dialog__container {
  transform: none !important;
}

.edit-loading-container {
  .mat-mdc-dialog-container {
    overflow-y: hidden !important;
  }
}

.cdk-overlay-container {
  z-index: 1050;
}

.cdk-overlay-dark-backdrop {
  opacity: 0.4 !important;
  background: $secondary;

}

.mat-mdc-select {
  padding-top: 0 !important;
}

.mat-select-trigger {
  border-radius: 0;
  height: 2rem;
  line-height: 2rem;
  padding-left: 1rem;
  width: 8rem;
}

.mat-checkbox-frame {
  border-width: 1px !important;
  border-radius: 0 !important;
}

.mat-checkbox-label {
  padding-left: 0.5rem;
  font-size: 0.9rem;
  font-family: "Avenir LT W01_35 Light";
}

$my-app-primary: mat.define-palette(mat.$light-blue-palette);
$my-app-accent: mat.define-palette(mat.$pink-palette, 500, 900, 100);
$my-app-warn: mat.define-palette(mat.$deep-orange-palette);

// // $my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn
    )
  )
);

@include mat.all-component-themes($my-app-theme);
