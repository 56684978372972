@import "colors";
@import "helpers";
@import "typography";
@import "fonts";
@import "mat_theme";
@import "icons";

body,
html {
  padding: 0;
  margin: 0;
  background-color: $secondary;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  -webkit-tap-highlight-color: transparent;

  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
}

body ::-webkit-scrollbar {
  display: none;
}

sup {
  line-height: 0 !important;
}


.container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.particle-background {
  background-image: url("/assets/icons/login-layout.png");
  background-repeat: no-repeat;
  width: 100%;
}

.d-none {
  display: none;
}

.siq-notification {
  position: fixed;
  bottom: 32px;

  @media (max-width: 599px) {
    bottom: 70px;
  }
}

.siq-privacy-banner {
  position: fixed;
  top: 88px;
  z-index: 2;
}

.details-icc-message {
  padding: 0 76px;

  @media (min-width: 600px) and (max-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 599px) {
    padding: 0 20px;
  }
}

.line-separator {
  height: 1px;
  border-radius: 2px;

  &.w-12 {
    width: 12px;
  }

  &.primary-100 {
    background-color: $primary-100;
  }
}

.square {
  width: 8px;
  height: 8px;

  &.details-page {
    width: 24px;
    border-radius: 2px;
  }

  &.time-to-fall-asleep {
    background-color: $basic-off-white;
  }

  &.restless {
    background-color: $yellow;
  }

  &.restful {
    background-color: $electric;
  }

  &.bed-exit {
    background-color: $hot-red;
  }
}

.circle {
  height: 72px;
  width: 72px;
  border-radius: 50%;

  &.wh-8 {
    width: 8px;
    height: 8px;
  }

  &.wh-48 {
    height: 48px;
    width: 48px;
  }

  &.wh-40 {
    height: 40px;
    width: 40px;
  }

  &.wh-14 {
    height: 14px;
    width: 14px;
  }

  &.wh-12 {
    height: 12px;
    width: 12px;
  }

  &.green {
    background-color: $electric;
  }

  &.blue {
    background-color: $rain-blue;
  }

  &.yellow {
    background-color: $yellow;
  }

  &.red {
    background-color: $hot-red;
  }
}

.spacer {
  height: 1px;
  background-color: $primary-300;
}

// siq input style
.input-container {
  position: relative;

  .input-field-sn {
    text-align: start;
    border: none;
    padding: 8px 0;
    outline: none !important;
  }

  .input-icon {
    position: absolute;
    bottom: 10px;
    right: 5px;
  }

  .invalid-bottom-border-input {
    border-bottom: 1px solid $hot-red-300;

    &.focus {
      border-width: 2px;
    }
  }

  .valid-bottom-border-input {
    border-bottom: 1px solid $primary-200;

    &.focus {
      border-bottom: 2px solid $basic-off-white;
    }
  }
}

input[id="siq-input"]::placeholder {
  color: $primary-200;
}

input:focus::placeholder {
  color: transparent;
}

// to move Safari password suggestion icon
input::-webkit-textfield-decoration-container {
  margin-right: 35px;
}

.w-74 {
  min-width: 74px;
}
