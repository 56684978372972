body {
  background-color: #001D34;
}
.sleep-chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.sleep-chart-container .sleep-chart-layer {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  z-index: 1;
  height: calc(100% - 30px);
}

.sleep-chart-container.details .sleep-chart-layer {
  height: calc(100% - 50px);
}

.sleep-chart-container .sleep-chart-layer,  .sleep-chart-container .sleep-chart-layer {
  transform: scaleY(0);
  transform-origin: bottom;
}

.sleep-chart-container .sleep-chart-layer.animate, .sleep-chart-container .sleep-chart-layer.animate {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.3s ease-in;
} 

.sleep-chart-container .timeline-layer {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
}

.sleep-chart-container .timeline-layer .small-timeline{
  position: relative;
  height: 20px;
  margin-top: 8px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 5px;
  padding-right: 5px;
}

.sleep-chart-container .timeline-layer .small-timeline .time-value{
  font-family: 'Avenir_400' !important;
  font-size: 0.75rem;
  line-height: 22px;
  color: #F2F5F7;
}


.sleep-chart-container .sleep-chart-layer .slice {
  display: flex;
}

.sleep-chart-container .sleep-chart-layer .slice.fall-asleep {
  background-color: #F2F5F7;
  height: 12px;
}
.sleep-chart-container .sleep-chart-layer .slice.restful {
  background: #6BFCAC;
  height: 36px;
}
.sleep-chart-container .sleep-chart-layer .slice.restless {
  background: #FFC511;
  height: 48px;
}
.sleep-chart-container .sleep-chart-layer .slice.out-of-bed {
  background: #F84B20;
  height: 48px;
} 

.sleep-chart-container .sleep-chart-layer .slice.hidden-slice {
  background-color: #F2F5F7;
  height: 12px;
}

/* details */

.sleep-chart-container .timeline-layer.details {
  margin-top: 12px;
}

.sleep-chart-container .timeline-layer.details .timeline-value {
  position: relative;
  box-sizing: border-box;
  height: 12px;
  border-right: 1px solid #CCD6DE;
}

.sleep-chart-container .timeline-layer.details .timeline-value .time {
  position: absolute;
  font-family: 'Avenir_400' !important;
  font-size: 16px;
  line-height: 22px;
  color: #CCD6DE;
  width: 18px;
  text-align: center;
  right: -11px;
  bottom: -28px;
}

.sleep-chart-container .timeline-layer.details .timeline-value .time-format {
  position: absolute;
  font-family: 'Avenir_400' !important;
  font-size: 14px;
  line-height: 18px;
  color: #CCD6DE;
  width: 21px;
  text-align: center;
  right: -11px;
  bottom: -42px;
}

/* tooltips */
.sleep-chart-container .sleep-chart-layer .tooltip-container {
  position: absolute;
  transform: translate(-100%);
  left: 100%;
  bottom: 0px;
  height: 80%;
  border-left: 1px solid #CCD6DE;
  border-right: 1px solid #CCD6DE;
  margin-left: 1px;
  font-family: 'Avenir_400' !important;
  font-size: 14px;
  color: #B5B6BA;
  box-sizing: content-box;
}

.sleep-chart-container .sleep-chart-layer .tooltip-container .time-value {
  width: 72px;
  height: 24px;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
}

.sleep-chart-container .sleep-chart-layer .tooltip-container .time-value.left {
  left: -12px;
}

.sleep-chart-container .sleep-chart-layer .tooltip-container .time-value.left.align-right {
  left: -60px;
}

.sleep-chart-container .sleep-chart-layer .tooltip-container .time-value.right {
  right: -16px;
}

/* edit */

.sleep-chart-container .sleep-chart-layer .edit-layer {
  position: absolute;
  top: 0;
  bottom: -50px;
  left: 0;
  right: 0;
  z-index: 2;
}

.sleep-chart-container .sleep-chart-layer .edit-layer .edit-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.edit-container .edit-part {
  position: absolute;
  top: 0;
  bottom: 50px;
  display: flex;
}

.edit-container .edit-part .white-overlay {
  position: absolute;
  width: calc(100% - 3px);
  background: #001D34;
  opacity: 0.6;
  height: 100%;
}

.edit-container .edit-part.left-edit .white-overlay {
  left: 0;
}

.edit-container .edit-part.right-edit .white-overlay {
  right: 0;
}


.edit-container .left-edit {
  left: 0;
  justify-content: flex-end;
}

.edit-container .right-edit {
  right: 0;
  justify-content: flex-start;
}

.edit-container .edit-part .dragger {
  position: absolute;
  top: 80px;
  bottom: -95px;
  width: 3px;
}

.edit-container .edit-part .dragger#left-dragger {
  right: 0;
}

.edit-container .edit-part .dragger#right-dragger {
  left: 0;
}

.edit-container .edit-part .dragger .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -25px;
  left: -18px;
  box-sizing: border-box;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: #85F5FF;
}

.edit-container .edit-part .dragger .inner-dragger-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color:#85F5FF;
}

.edit-container .edit-part .dragger.limit .inner-dragger-container {
  background-color: #DC2241;
}

.dragger .time-value {
  position: absolute;
  top: -34px;
  left: -8px;
  width: 65px;
  height: 28px;
  background-color: transparent;
  color: #F2F5F7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  font: 14px "Avenir_900" !important;
  inline-size: 18px;
  overflow-wrap: break-word;
}

.dragger .touch-field {
  position: absolute;
  left: -30px;
  right: -30px;
  top: 10px;
  bottom: -30px;
  z-index: 1000;
}