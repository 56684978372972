body {
  background-color: #001D34;
  margin: 0;
}

.domain {
  display: none;
}

.tick {
  font-family: 'Avenir_900';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  color: #F2F5F7;
}

.tick text {
  font-family: 'Avenir_900';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
}

.circle-text {
  font-family: 'Avenir_900';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
}

.horizontal-line {
  display: block;
  fill: none;
  stroke: #6BFCAC;
  stroke-width: 1px;
  stroke-dasharray: 3 ;
  opacity: 0.3;
}
