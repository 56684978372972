@import "colors";

%icon {
  background-size: contain;
  display: inline-block;
  mask-repeat: no-repeat;
}

@mixin siq-icon($width, $height, $icon-title, $icon-position-x, $icon-position-y) {
  background: url("/assets/icons/" + $icon-title + ".svg") $icon-position-x $icon-position-y/contain no-repeat;
  width: $width;
  height: $height;
}

@mixin sn-logo-position($position) {
  position: absolute;
  bottom: -16px;
  #{$position}: -24px;
  z-index: -1;
}

.sn-logo {
  @extend %icon;
  @include siq-icon(170px, 28px, "sn-logo", left, bottom);

  &.login {
    @include siq-icon(296px, 48px, "sn-logo", left, bottom);
  }
}

.sn-logo-dark-left {
  @extend %icon;
  @include siq-icon(92px, 92px, "sn-logo-dark", left, bottom);
  @include sn-logo-position(left);
}

.sn-logo-dark-right {
  @extend %icon;
  @include siq-icon(92px, 92px, "sn-logo-dark", left, bottom);
  @include sn-logo-position(right);
}

.back-arrow {
  @extend %icon;
  @include siq-icon(20px, 14px, "back-arrow", left, bottom);
}

.arrow-right {
  @extend %icon;
  @include siq-icon(8px, 14px, "right-arrow", left, bottom);
}

.arrow-icon-down {
  @extend %icon;
  @include siq-icon(12px, 6px, "down-arrow", left, bottom);
}

.arrow-left {
  @extend %icon;
  @include siq-icon(8px, 14px, "arrow-left", center, center);
}

.arrow-left-disabled {
  @extend %icon;
  @include siq-icon(8px, 14px, "arrow-left-disabled", center, center);
}

.arrow-right-disabled {
  @extend %icon;
  @include siq-icon(8px, 14px, "arrow-right-disabled", center, center);
}

.right-arrow-icon {
  @extend %icon;
  @include siq-icon(14px, 14px, "right-arrow-btn-icon", center, center);
}

.down-arrow-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "down-arrow-icon", center, center);
}

.check-icon {
  @extend %icon;
  @include siq-icon(14px, 10px, "green-checkmark", left, bottom);

  &.wh-20 {
    width: 20px;
    height: 20px;
  }

  &.wh-14 {
    width: 14px;
    height: 14px;
  }
}

.white-checkmark {
  @extend %icon;
  @include siq-icon(71px, 71px, "white-checkmark", center, center);
}

.checked-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "checked-icon", center, center);
}

.unchecked-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "unchecked-icon", center, center);
}

// popup icons

.siq-logo {
  @include siq-icon(40px, 40px, "siq-logo", center, center);

  background-size: contain;
}

.info-icon {
  @include siq-icon(40px, 40px, "info-icon", center, center);

  background-size: contain;

  &.wh-26 {
    width: 26px;
    height: 26px;
  }
}

.info-icon-white {
  @include siq-icon(20px, 20px, "info-icon-white", center, center);

  background-size: contain;
}

.info-icon-blue {
  @include siq-icon(24px, 24px, "info-icon-blue", center, center);

  background-size: contain;
}

.info-primary {
  @include siq-icon(32px, 32px, "info-primary", center, center);

  background-size: contain;
}

.dark-checkmark {
  @include siq-icon(40px, 40px, "dark-checkmark", center, center);

  background-size: contain;
}

.warning-icon {
  @include siq-icon(40px, 40px, "warning-icon", center, center);

  background-size: contain;

  &.wh-18 {
    width: 18px;
    height: 18px;
  }
}

.warning-white-icon {
  @include siq-icon(72px, 72px, "warning-white-icon", center, center);
}

.close-gray-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "close-gray-icon", center, center);
}

.close-primary-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "close-primary-icon", center, center);
}

.close-white-icon {
  @extend %icon;
  @include siq-icon(14px, 14px, "close-white-icon", center, center);
}

.app-store-white-icon {
  @extend %icon;
  @include siq-icon(153px, 44px, "app-store-white", left, bottom);
}

.google-play-store-white-icon {
  @extend %icon;
  @include siq-icon(153px, 44px, "google-play-store-white", left, bottom);
}

.hide-password-icon {
  @extend %icon;
  @include siq-icon(20px, 19px, "hide-password-icon", left, bottom);
}

.show-password-icon {
  @extend %icon;
  @include siq-icon(18px, 12px, "show-password-icon", left, bottom);
}

.duration-goal-blue-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "duration-goal-blue", left, bottom);
}

.sleep-health-no-data-icon {
  @extend %icon;
  @include siq-icon(40px, 40px, "sleep-health-no-data", left, bottom);
}

.wellness-report-not-available {
  @extend %icon;
  @include siq-icon(64px, 64px, "report-not-available", left, bottom);

  &.w-24 {
    width: 24px;
    height: 24px;
  }
}

.siq-score-chart {
  @extend %icon;
  @include siq-icon(328px, 68px, "siq-score-chart", left, bottom);
}

.learn-about-sleep-health {
  @extend %icon;
  @include siq-icon(168px, 40px, "learn-about-sleep-health", left, bottom);
}

.moon-sleeping {
  @extend %icon;
  @include siq-icon(72px, 72px, "moon-sleeping", left, bottom);

  &.w-24 {
    width: 24px;
    height: 24px;
  }
}

.mobile-app-screens {
  @extend %icon;
  @include siq-icon(286px, 210px, "mobile-app-screens", left, bottom);
}

.trends-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "trends", left, bottom);
}

.biosignals-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "biosignals", left, bottom);
}

.circadian-rhythm-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "circadian-rhythm-icon", left, bottom);
}

.download-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "download", center, center);
}

.heart-soft-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "heart-soft", center, center);
}

.hrv-soft-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "hrv-soft", center, center);
}

.breath-rate-soft-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "breath-rate-soft", center, center);
}

.heart-soft-not-available-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "heart-soft-not-available", center, center);

  &.wh-48 {
    width: 48px;
    height: 48px;
  }
}

.hrv-soft-not-available-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "hrv-soft-not-available", center, center);

  &.wh-48 {
    width: 48px;
    height: 48px;
  }
}

.breath-rate-soft-not-available-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "breath-rate-soft-not-available", center, center);

  &.wh-48 {
    width: 48px;
    height: 48px;
  }
}

.bedtime-blue-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "bedTime", center, center);
}

.bedtime-gray-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "bedtime-gray", center, center);
}

.bedtime-gray-icon-small {
  @extend %icon;
  @include siq-icon(24px, 24px, "bedtime-gray", center, center);
}

.wake-time-blue-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "wakeTime", center, center);
}

.wake-time-gray-icon {
  @extend %icon;
  @include siq-icon(32px, 32px, "wake-time-gray", center, center);
}

.wake-time-gray-icon-small {
  @extend %icon;
  @include siq-icon(24px, 24px, "wake-time-gray", center, center);
}

.stopwatch-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "stopwatch", center, center);
}

.cr-workout-icon {
  @extend %icon;
  @include siq-icon(40px, 40px, "cr-workout", center, center);
}

.cr-workout-gray-icon{
  @extend %icon;
  @include siq-icon(24px, 24px, "cr-workout-gray", center, center);
}

.cr-alert-icon {
  @extend %icon;
  @include siq-icon(40px, 40px, "cr-alert", center, center);
}

.cr-alert-gray-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "cr-alert-gray", center, center);
}

.cr-dinner-icon {
  @extend %icon;
  @include siq-icon(40px, 40px, "cr-dinner", center, center);
}

.cr-dinner-gray-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "cr-dinner-gray", center, center);
}

.cr-wind-down-icon {
  @extend %icon;
  @include siq-icon(40px, 40px, "cr-wind-down", center, center);
}

.cr-wind-down-gray-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "cr-wind-down-gray", center, center);
}

.cr-activities-icon {
  @extend %icon;
  @include siq-icon(294px, 168px, "cr-activities", center, center);
}

.history-no-data {
  @extend %icon;
  @include siq-icon(32px, 32px, "history-no-data", center, center);
}

.calendar-icon {
  @extend %icon;
  @include siq-icon(71px, 71px, "calendar", center, center);
}

// why is this important icons
.sn-sleep-performance {
  @extend %icon;
  @include siq-icon(100%, 168px, "sn-sleep-performance", center, center);
}

.siq-particle {
  @extend %icon;
  @include siq-icon(100%, 168px, "siq-partical", center, center);
}

.sn-best-rest {
  @extend %icon;
  @include siq-icon(100%, 168px, "sn-best-rest", center, center);
}

.sn-sleep-disruptions {
  @extend %icon;
  @include siq-icon(100%, 168px, "sn-sleep-disruptions", center, center);
}

.hrv-monitor {
  @extend %icon;
  @include siq-icon(100%, 168px, "monitor-hrv", center, center);
}

.hrv-instructions {
  @extend %icon;
  @include siq-icon(100%, 168px, "what-is-hrv", center, center);
}

.hrv-wellbeing {
  @extend %icon;
  @include siq-icon(100%, 94px, "hrv-wellbeing", center, center);
}

.hrv-low {
  @extend %icon;
  @include siq-icon(100%, 94px, "low-hrv", center, center);
}

.hrv-effects {
  @extend %icon;
  @include siq-icon(100%, 168px, "effects-on-hrv", center, center);
}

.cr-internal-clock {
  @extend %icon;
  @include siq-icon(100%, 168px, "cr-internal-clock", center, center);
}

.cr-optimize-day {
  @extend %icon;
  @include siq-icon(100%, 222px, "cr-optimize-day", center, center);
}

.cr-activities {
  @extend %icon;
  @include siq-icon(100%, 168px, "cr-activities", center, center);
}

.cr-consistency {
  @extend %icon;
  @include siq-icon(100%, 170px, "cr-consistency", center, center);
}

.sn-sleep-performance-onboarding {
  @extend %icon;
  @include siq-icon(100%, 168px, "sn-sleep-performance-onboarding", center, center);
}

.sleep-chart {
  @extend %icon;
  @include siq-icon(100%, 145px, "sleep-chart", center, center);
}

.biosignals-icons {
  @extend %icon;
  @include siq-icon(100%, 125px, "biosignals-icons", center, center);
}

.duration-chart {
  @extend %icon;
  @include siq-icon(100%, 170px, "duration-chart", center, center);
}

.efficiency-chart {
  @extend %icon;
  @include siq-icon(100%, 170px, "efficiency-chart", center, center);
}

.timing-chart {
  @extend %icon;
  @include siq-icon(100%, 170px, "timing-chart", center, center);
}

.sleep-trends-chart {
  @extend %icon;
  @include siq-icon(100%, 74px, "sleep-trends-chart", center, center);
}

// beds
.dual-bed-icon {
  @extend %icon;
  @include siq-icon(200px, 64px, "dual-bed-icon", center, center);

  &.big {
    @include siq-icon(303px, 96px, "dual-bed-icon", center, center);
  }
}

.dual-bed-icon-rotated {
  @extend %icon;
  @include siq-icon(303px, 96px, "baseline-bed", center, center);

  &.w-236 {
    @include siq-icon(236px, 96px, "baseline-bed", center, center);
  }
}

.dual-bed-gray-icon {
  @extend %icon;
  @include siq-icon(200px, 64px, "dual-bed-gray", center, center);

  &.big {
    @include siq-icon(303px, 96px, "dual-bed-gray", center, center);
  }
}

.single-bed-icon {
  @extend %icon;
  @include siq-icon(200px, 64px, "single-bed-icon", center, center);

  &.big {
    @include siq-icon(303px, 96px, "single-bed-icon", center, center);
  }
}

.single-bed-gray-icon {
  @extend %icon;
  @include siq-icon(200px, 64px, "single-bed-gray", center, center);

  &.big {
    @include siq-icon(303px, 96px, "single-bed-gray", center, center);
  }
}

.bed-on-the-way {
  @extend %icon;
  @include siq-icon(320px, 200px, "bed-on-the-way", center, center);
}

.bed-arrived {
  @extend %icon;
  @include siq-icon(320px, 200px, "bed-arrived", center, center);
}

.blue-bed-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "blue-bed", center, center);

  &.wh-48 {
    width: 48px;
    height: 48px;
  }
}

.dual-bed-left-side-selected {
  @extend %icon;
  @include siq-icon(303px, 96px, "dual-bed-left-side-selected", center, center);
}

.dual-bed-right-side-selected {
  @extend %icon;
  @include siq-icon(303px, 96px, "dual-bed-right-side-selected", center, center);
}

.unconfigured-bed-icon {
  @extend %icon;
  @include siq-icon(71px, 71px, "unconfigured-bed-icon", center, center);
}

// profile
.sleeper {
  @extend %icon;
  @include siq-icon(40px, 40px, "sleeper", center, center);
}

.home {
  @extend %icon;
  @include siq-icon(48px, 48px, "home", center, center);
}

.habits {
  @extend %icon;
  @include siq-icon(48px, 48px, "habits", center, center);
}

.heart {
  @extend %icon;
  @include siq-icon(48px, 48px, "sleep-health", center, center);
}

.sleep-science-research {
  @extend %icon;
  @include siq-icon(64px, 64px, "sleep-science-research", center, center);

  &.wh-48 {
    width: 48px;
    height: 48px;
  }
}

.edit-icon {
  @extend %icon;
  @include siq-icon(18px, 18px, "edit", center, center);
}

.edit-light-icon {
  @extend %icon;
  @include siq-icon(18px, 18px, "edit-light", center, center);
}

.sleep-research {
  @extend %icon;
  @include siq-icon(48px, 48px, "sleep-research", center, center);
}

.how-it-works {
  @extend %icon;
  @include siq-icon(48px, 48px, "how-it-works", center, center);
}

.your-privacy {
  @extend %icon;
  @include siq-icon(48px, 48px, "your-privacy", center, center);
}

.sleep-science-bed {
  @extend %icon;
  @include siq-icon(24px, 24px, "sleep-science-bed", center, center);
}

.sleep-science-edit {
  @extend %icon;
  @include siq-icon(24px, 24px, "sleep-science-edit", center, center);
}

.sleep-science-hrv {
  @extend %icon;
  @include siq-icon(24px, 24px, "sleep-science-hrv", center, center);
}

.sleep-science-bulb {
  @extend %icon;
  @include siq-icon(24px, 24px, "sleep-science-bulb", center, center);
}

.mail-icon {
  @extend %icon;
  @include siq-icon(72px, 72px, "mail", center, center);
}

.wifi-online {
  @extend %icon;
  @include siq-icon(40px, 40px, "wifi-online", center, center);
}

.wifi-offline {
  @extend %icon;
  @include siq-icon(40px, 40px, "wifi-offline", center, center);
}

.lock-key-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "lock-key", center, center);

  &.wh-72 {
    width: 72px;
    height: 72px;
  }
}

.signal-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "signal", center, center);
}

.time-icon {
  @extend %icon;
  @include siq-icon(72px, 72px, "time", center, center);
}

.particle-success {
  @extend %icon;
  @include siq-icon(150px, 150px, "particle_success", center, center);
}

.unselected-bed-left-side {
  @extend %icon;
  @include siq-icon(152px, 96px, "unselected-bed-left-side", center, center);
}

.unselected-bed-right-side {
  @extend %icon;
  @include siq-icon(152px, 96px, "unselected-bed-right-side", center, center);
}

.selected-bed-left-side {
  @extend %icon;
  @include siq-icon(152px, 96px, "selected-bed-left-side", center, center);
}

.selected-bed-right-side {
  @extend %icon;
  @include siq-icon(152px, 96px, "selected-bed-right-side", center, center);
}

.plus-blue-icon {
  @extend %icon;
  @include siq-icon(24px, 24px, "plus-blue", center, center);
}

.locked {
  @extend %icon;
  @include siq-icon(22px, 22px, "locked", center, center);
}

.available {
  @extend %icon;
  @include siq-icon(22px, 22px, "available", center, center);
}

.snore-insights {
  @extend %icon;
  @include siq-icon(100%, 168px, "snore-insights", center, center);
}

.respiratory-health-chart {
  @extend %icon;
  @include siq-icon(90%, 220px, "respiratory-health-chart", center, center);
}

.breathe-iq-icon {
  @extend %icon;
  @include siq-icon(72px, 72px, "breatheIq-icon", center, center);
}
