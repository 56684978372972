.hrv-chart-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.hrv-chart-container .marker-container {
    width: 300px;
    height: 300px;
    transform: rotate(-53deg);
}

.hrv-marker svg g path#svg-marker {
    fill: #DDEC7D;
}

.hrv-path {
    display: inline-block;
    mask-repeat: no-repeat;
    background: url("../lib/hrv-path.svg") top no-repeat;
    background-size: contain;
    width: 300px;
    height: 80px;
}


.hrv-marker {
    display: inline-block;
    width: 60px;
    height: 75px;
    position: absolute;
    transform: rotate(31deg);
    left: 115px;
    top: 25px;
}

.hrv-chart-container .marker-container.animate-0 {
    transform: rotate(-53deg);
    transition: transform calc(0 * 1.75s) ease-out;
}

.animate-0 .hrv-marker svg g path#svg-marker {
    fill: #DDEC7D;
    transition: fill calc(0 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-5{
    transform: rotate(-47deg);
    transition: transform calc(0.05 * 1.75s) ease-out;
}

.animate-5 .hrv-marker svg g path#svg-marker {
    fill: #D7EA7E;
    transition: fill calc(0.05 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-10{
    transform: rotate(-41deg);
    transition: transform calc(0.1 * 1.75s) ease-out;
}

.animate-10 .hrv-marker svg g path#svg-marker {
    fill: #D1E77F;
    transition: fill calc(0.1 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-15{
    transform: rotate(-35deg);
    transition: transform calc(0.15 * 1.75s) ease-out;
}

.animate-15 .hrv-marker svg g path#svg-marker {
    fill: #CBE480;
    transition: fill calc(0.15 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-20{
    transform: rotate(-30deg);
    transition: transform calc(0.20 * 1.75s) ease-out;
}

.animate-20 .hrv-marker svg g path#svg-marker {
    fill: #C5E181;
    transition: fill calc(0.2 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-25{
    transform: rotate(-25deg);
    transition: transform calc(0.25 * 1.75s) ease-out;
}

.animate-25 .hrv-marker svg g path#svg-marker {
    fill: #BFDF82;
    transition: fill calc(0.25 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-30{
    transform: rotate(-20deg);
    transition: transform calc(0.30 * 1.75s) ease-out;
}

.animate-30 .hrv-marker svg g path#svg-marker {
    fill: #B9DC82;
    transition: fill calc(0.30 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-35{
    transform: rotate(-15deg);
    transition: transform calc(0.35 * 1.75s) ease-out;
}

.animate-35 .hrv-marker svg g path#svg-marker {
    fill: #B3DA83;
    transition: fill calc(0.35 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-40{
    transform: rotate(-10deg);
    transition: transform calc(0.40 * 1.75s) ease-out;
}

.animate-40 .hrv-marker svg g path#svg-marker {
    fill: #ADD784;
    transition: fill calc(0.4 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-45{
    transform: rotate(-5deg);
    transition: transform calc(0.45 * 1.75s) ease-out;
}

.animate-45 .hrv-marker svg g path#svg-marker {
    fill: #A8D585;
    transition: fill calc(0.45 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-50{
    transform: rotate(0deg);
    transition: transform calc(0.50 * 1.75s) ease-out;
}

.animate-50 .hrv-marker svg g path#svg-marker {
    fill: #A2D286;
    transition: fill calc(0.50 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-55{
    transform: rotate(5deg);
    transition: transform calc(0.55 * 1.75s) ease-out;
}

.animate-55 .hrv-marker svg g path#svg-marker {
    fill: #9CCF87;
    transition: fill calc(0.55 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-60{
    transform: rotate(10deg);
    transition: transform calc(0.60 * 1.75s) ease-out;
}

.animate-60 .hrv-marker svg g path#svg-marker {
    fill: #96CC88;
    transition: fill calc(0.6 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-65{
    transform: rotate(15deg);
    transition: transform calc(0.65 * 1.75s) ease-out;
}

.animate-65 .hrv-marker svg g path#svg-marker {
    fill: #90CA89;
    transition: fill calc(0.65 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-70{
    transform: rotate(20deg);
    transition: transform calc(0.70 * 1.75s) ease-out;
}

.animate-70 .hrv-marker svg g path#svg-marker {
    fill: #8AC78A;
    transition: fill calc(0.70 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-75{
    transform: rotate(25deg);
    transition: transform calc(0.75 * 1.75s) ease-out;
}

.animate-75 .hrv-marker svg g path#svg-marker {
    fill: #84C58B;
    transition: fill calc(0.75 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-80{
    transform: rotate(30deg);
    transition: transform calc(0.80 * 1.75s) ease-out;
}

.animate-80 .hrv-marker svg g path#svg-marker {
    fill: #7EC28B;
    transition: fill calc(0.80 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-85{
    transform: rotate(35deg);
    transition: transform calc(0.85 * 1.75s) ease-out;
}

.animate-85 .hrv-marker svg g path#svg-marker {
    fill: #78BF8C;
    transition: fill calc(0.85 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-90{
    transform: rotate(41deg);
    transition: transform calc(0.90 * 1.75s) ease-out;
}

.animate-90 .hrv-marker svg g path#svg-marker {
    fill: #72BC8D;
    transition: fill calc(0.90 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-95{
    transform: rotate(47deg);
    transition: transform calc(0.95 * 1.75s) ease-out;
}

.animate-95 .hrv-marker svg g path#svg-marker {
    fill: #6CBA8E;
    transition: fill calc(0.95 * 1.75s) linear;
}

.hrv-chart-container .marker-container.animate-100{
    transform: rotate(53deg);
    transition: transform calc(1 * 1.75s) ease-out;
}

.animate-100 .hrv-marker svg g path#svg-marker {
    fill: #66B78F;
    transition: fill calc(1 * 1.75s) linear;
}