@mixin siq-btn-text-style($font-size, $line-height, $font-weight, $text-color: null) {
  font-family: 'Avenir_#{$font-weight}';
  font-style: normal;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;

  @if $text-color {
    color: $text-color;
  } @else {
    color: $rain-blue;
  }
}

// font-family

.siq-text-900 {
  font-family: "Avenir_900";
}

.siq-text-800 {
  font-family: "Avenir_800";
}

.siq-text-500 {
  font-family: "Avenir_500";
}

.siq-text-400 {
  font-family: "Avenir_400";
}

.siq-text-300 {
  font-family: "Avenir_300";
}

// font sizes

.fs-20 {
  font-size: 20px;
  line-height: 27px;
}

.fs-12 {
  font-size: 12px;
  line-height: 16px;
}

.fs-18 {
  font-size: 18px;
  line-height: 25px;
}

.fs-16 {
  font-size: 16px;
  line-height: 22px;
}

.fs-14 {
  font-size: 14px;
  line-height: 18px;
}

.fs-24 {
  font-size: 24px;
  line-height: 33px;
}

.fs-26 {
  font-size: 26px;
  line-height: 35px;
}

.fs-36 {
  font-size: 36px;
  line-height: 66px;
}

.fs-48 {
  font-size: 48px;
  line-height: 49px;
}

.fs-58 {
  font-size: 58px;
  line-height: normal;
}

// text color

.white {
  color: $basic-off-white;
}

.primary {
  color: $primary;
}

.dark-blue {
  color: $secondary;
}

.primary-200 {
  color: $primary-200;
}

.primary-300 {
  color: $primary-300;
}

.primary-500 {
  color: $primary-500;
}

.secondary {
  color: $secondary;
}

.rain-blue {
  color: $rain-blue;
}

.red {
  color: $hot-red-300;
}

.electric {
  color: $electric;
}

.yellow {
  color: $yellow;
}

// text decoration
.no-outline {
  outline: none;
  text-decoration: none;
}

// text alignment

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-end {
  text-align: end;
}

.text-wrap {
  text-wrap: wrap;
}

// text transform

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.tab-text {
  max-width: 80px;
  text-align: center;
}

.bold {
  font-weight: bold;
}

.text-no-wrap {
  white-space: nowrap;
}

.opacity-50 {
  opacity: 0.5;
}

.w-59 {
  width: 59px !important;
  min-width: 59px !important;
  max-width: 59px !important;
}

.mt-neg-48 {
  margin-top: -48px;
}
